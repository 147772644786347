import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const TaskForm = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const titleRef = useRef(null);
  const dateeRef = useRef(null);
  const tt  = Math.floor(Math.random() * 1000000);
  const [error, setError] = useState(false)

  function handleDateChange(date) {
    setSelectedDate(date);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(titleRef.current.value);
    console.log(selectedDate);
if(titleRef.current.value.length < 1 || selectedDate === null){
  setError(true)
  return false;
}
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: tt,
        name: titleRef.current.value,
        value: 100,
        status: "todo",
        order: 1,
        duedate: selectedDate,
      }),
    };

    try {
      const response = await fetch(
        `https://react-test-f1c7d-default-rtdb.firebaseio.com/tasks/${tt}.json`,
        requestOptions
      );
      const data = await response.json();
      console.log(data);
      // Reset the input fields
      titleRef.current.value = "";
      setSelectedDate(null);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-100">
      <div>{error && "Enter both required fields"}</div>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
        </label>
          <input type="text" ref={titleRef} />
        <label>
          Date:
        </label>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          ref={dateeRef}
          defaultShow="true"
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default React.memo(TaskForm);
