import React, { useState, useEffect, useMemo  } from "react";
import TaskForm from "./TaskForm";
import Task from "./Task";

const Tasks = () => {


    const tasksObj = [
        { "id": 1, "name": "Mindfullness / ධාර්මික දිවිපැවැත්ම", "value": 100, "status": "active", "order": 1, "duedate": '2022-02-01' },
        { "id": 2, "name": "Neglect OC / Building Healthy Pesonality", "value": 200, "status": "active", "order": 2, "duedate": '2022-02-01' },
        { "id": 22, "name": "Meditation, Breathing ex, Gratitude, Cardio ex, Walking and Gym", "value": 200, "status": "active", "order": 3, "duedate": '2022-02-01' },
        { "id": 3, "name": "React Js 48hrs Course", "value": 300, "status": "active", "order": 4, "duedate": '2022-02-01' },
        { "id": 32, "name": "Python Course", "value": 300, "status": "active", "order": 5, "duedate": '2022-02-01' },
        { "id": 4, "name": "Initiating an own Business - creating business plan", "value": 400, "status": "active", "order": 6, "duedate": '2022-02-01' },
        { "id": 5, "name": "Earning $700/$15k and Invest $55/$2.5k", "value": 500, "status": "active", "order": 7, "duedate": '2022-02-01' },
        { "id": 6, "name": "Senior Software Engineer - Initiating React upgrade", "value": 600, "status": "active", "order": 8, "duedate": '2022-02-01' },
        { "id": 7, "name": "Time Tracker", "value": 700, "status": "active", "order": 9, "duedate": '2022-02-01' },
        { "id": 8, "name": "SSAA", "value": 800, "status": "active", "order": 10, "duedate": '2022-02-01' },
        { "id": 9, "name": "Coach Bathiya ", "value": 900, "status": "active", "order": 11, "duedate": '2022-02-01' },
        { "id": 10, "name": "Rich dad book completing", "value": 1000, "status": "active", "order": 12, "duedate": '2022-02-01' },
        { "id": 11, "name": "Orangetale", "value": 1100, "status": "active", "order": 13, "duedate": '2022-02-01' },
        { "id": 12, "name": "IELTS - DP Grammer Initiating", "value": 1200, "status": "active", "order": 14, "duedate": '2022-02-01' }]


    const [tasks, setTasks] = useState();
    
    const [taskAvailablity, setTaskAvailablity] = useState([{}]);
    
    useEffect(() => {

        const fetchData = async () => {
            const url = 'https://react-test-f1c7d-default-rtdb.firebaseio.com/tasks.json';
            const response = await fetch(url);
            if (response.ok) {
              const responseData = await response.json();  
              if(responseData){
                let updatedtasksArray = Object.values(responseData);
                updatedtasksArray = updatedtasksArray.filter(n => n)
                
                updatedtasksArray.sort((a, b) => a.order - b.order);
                setTasks(updatedtasksArray)
                setTaskAvailablity(responseData);  
              } 
            } else {
              console.log('Error fetching data from Firebase');
            }
          };
      
          fetchData();
        console.log(taskAvailablity)
       
      }, []); 

      const saveData = async (id, tasksToUpdate) => {


        const url = `https://react-test-f1c7d-default-rtdb.firebaseio.com/tasks/${id}.json`;
            const taskAvailable = await fetch(url);
            if (taskAvailable.ok) {
              const availability = await taskAvailable.json();  
              if(availability){

                setTaskAvailablity(availability); 
              } 
            }
        
      const requestOptions = {
        method:  taskAvailablity ? 'PATCH' : 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(tasksToUpdate),
      };


        try {
          const response = await fetch(`https://react-test-f1c7d-default-rtdb.firebaseio.com/tasks/${id}.json`, requestOptions);
          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error(error);
        }
      } 


    const remove = async (e) => {
      let targetId = parseInt(e.target.dataset.id);


        try {
          const response = await fetch(`https://react-test-f1c7d-default-rtdb.firebaseio.com/tasks/${targetId}.json`, {
            method:  'DELETE'
          });
          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error(error);
        }

      const updatedtasks = [
        ...tasks.filter((task) => task.id !== targetId)
    ];

    // Sort the updated courses array by their order values
    updatedtasks.sort((a, b) => a.order - b.order);

    setTasks(updatedtasks)

    }

    const upHandler = (e) => {

        let targetId = parseInt(e.target.dataset.id);
        // Find the task with id 4
        const tasksToUpdate = tasks.find((task) => task.id === targetId);
        // Update the order value of the task
        tasksToUpdate.order = tasksToUpdate.order - 1;
        // tasksToUpdate.id = targetId;
console.log(tasksToUpdate);
        // Create a new array with the updated task and other tasks
        const updatedtasks = [
            ...tasks.filter((task) => task.id !== targetId),
            tasksToUpdate,
        ];

        // Sort the updated courses array by their order values
        updatedtasks.sort((a, b) => a.order - b.order);

        setTasks(updatedtasks)
        saveData(targetId, tasksToUpdate)
    }
    const downHandler = (e) => {
        let targetId = parseInt(e.target.dataset.id);
        // Find the task with id 4
        const tasksToUpdate = tasks.find((task) => task.id === targetId);
        // Update the order value of the task
        tasksToUpdate.order = tasksToUpdate.order + 1;
        // tasksToUpdate.id = targetId;

        // Create a new array with the updated task and other tasks
        const updatedtasks = [
            ...tasks.filter((task) => task.id !== targetId),
            tasksToUpdate,
        ];

        // Sort the updated courses array by their order values
        updatedtasks.sort((a, b) => a.order - b.order);

        setTasks(updatedtasks)
        saveData(targetId, tasksToUpdate)
    }

    const increaseValueHandler = (e) => { 
        let targetId = parseInt(e.target.dataset.id);
        // Find the task with id 4
        const tasksToUpdate = tasks.find((task) => task.id === targetId);
        // Update the order value of the task
        tasksToUpdate.value = tasksToUpdate.value + 1;
        // tasksToUpdate.id = targetId;

        // Create a new array with the updated task and other tasks
        const updatedtasks = [
            ...tasks.filter((task) => task.id !== targetId),
            tasksToUpdate,
        ];

        // Sort the updated courses array by their order values
        updatedtasks.sort((a, b) => a.order - b.order);

        setTasks(updatedtasks)
        saveData(targetId, tasksToUpdate)
    }
    const changeStatus = (e) => { 
      let targetId = parseInt(e.target.dataset.id);
      let status = e.target.dataset.status;
      let statuses = ['todo', 'active', 'completed', 'closed', 'archived'];
      // Find the task with id 4
      const tasksToUpdate = tasks.find((task) => task.id === targetId);
      // Update the order value of the task
      tasksToUpdate.status = statuses[statuses.indexOf(status) + 1] === undefined ? 'todo' : statuses[statuses.indexOf(status) + 1];
      // tasksToUpdate.id = targetId;

      // Create a new array with the updated task and other tasks
      const updatedtasks = [
          ...tasks.filter((task) => task.id !== targetId),
          tasksToUpdate,
      ];

      // Sort the updated courses array by their order values
      updatedtasks.sort((a, b) => a.order - b.order);

      setTasks(updatedtasks)
      saveData(targetId, tasksToUpdate)

      console.log()
  }
    
    return (
        <>
            <div>
                <TaskForm />
            </div>
            <div>
                {tasks && tasks.map((v) => <Task key={v.id} task={v} downHandler={downHandler} upHandler={upHandler} increaseValueHandler={increaseValueHandler} changeStatus={changeStatus} remove={remove}/>)}
            </div>
        </>
    );
}

export default Tasks;