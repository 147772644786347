import React from "react";

const Task = (props) => {
      const date = new Date(props.task.duedate); 
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
    
      return (
            <div className="task-item" >
                  <span className="delete" onClick={props.remove} data-id={props.task.id}>x</span>
                  <div className="btn-wrapper">
                        <span className="up-btn" onClick={props.upHandler} data-id={props.task.id}>&uarr;</span>
                        <span className="down-btn" onClick={props.downHandler} data-id={props.task.id}>&darr;</span> 
                   </div>
                  <span className="title" onClick={props.increaseValueHandler} data-id={props.task.id}> {props.task.name} </span>
                  <span className="wrapper-span">
                  {/* <span className="value"> {props.task.value} </span> */}
                   <span className="due-date">{dateString}</span>
                   </span>
                   <span className="status wrapper-span"  onClick={props.changeStatus} data-id={props.task.id}  data-status={props.task.status}> {props.task.status} <span className="value"> {props.task.value} </span></span>
                  
                  
            </div>
      );
}

export default Task;